/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import classes from '../../Annotation/Pages/Components/AnnotationJobsTable/AnnotationJobsTable.module.scss'
import React, {useEffect, useReducer, useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
//@ts-ignore
import {Link, useNavigate, useParams} from "react-router-dom";
//@ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {batchArchiveCommunity, batchDeleteCommunity, getAppsNames, getCommunity} from "../../../../store/actions";
import '../community.scss'
import tableClasses from './table.module.scss';
import {AppMode} from "../../../../AUTO_GENERATED_TYPES";
import {SessionStorage} from "../../../../constants/sessionStorage";
import ArchiveSwitch from "../Components/ArchiveSwitch/ArchiveSwitch";
import TablePagination from '@mui/material/TablePagination';
import { Pagination } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface CommunityTableFilters {
    appMode: AppMode,
    archiveMode: boolean,
    appName: number | null
}

const initialState: CommunityTableFilters = {
    appMode: AppMode.LIVE,
    archiveMode: false,
    appName: null,
};

enum ActionType {
    SET_APP_MODE = "SET_APP_MODE",
    SET_APP_NAME = "SET_APP_NAME",
    TOGGLE_ARCHIVE = "TOGGLE_ARCHIVE"
}

interface SetAppModeAction {
    type: ActionType.SET_APP_MODE;
    payload: AppMode;
}

interface ToggleArchiveModeAction {
    type: ActionType.TOGGLE_ARCHIVE;
}

interface SetAppNameAction {
    type: ActionType.SET_APP_NAME;
    payload: number | null;
}

type Action = SetAppModeAction | SetAppNameAction | ToggleArchiveModeAction

function reducer(state: CommunityTableFilters, action: Action): CommunityTableFilters {
    switch (action.type) {
        case ActionType.SET_APP_MODE:
            return {...state, appMode: action.payload, appName: null};
        case ActionType.TOGGLE_ARCHIVE:
            return {...state, archiveMode: !state.archiveMode};
        case ActionType.SET_APP_NAME:
            return {...state, appName: action.payload};
        default:
            return state;
    }
}

const CommunityTable = () => {
    const params = useParams()
    const [{appMode, appName, archiveMode}, dispatchState] = useReducer(reducer, initialState);
    const [isAppSet, setIsAppSet] = useState(false)

    const {
        isPreloader
    } = useSelector((state: RootState) => ({
        isPreloader: state.Layout.isPreloader,
    }))

    const {communityList, appNamesList} = useSelector((state: RootState) => ({
        communityList: state.community.communityList,
        appNamesList: state.community.appNamesList
    }));

    const [page, setPage] = useState<number>(0);
    const [sizePerPage, setSizePerPage] = useState<number>(25);
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchField, setSearchField] = useState<string>("");

    const limit = (page) * sizePerPage;
    const totalInstances = communityList.total_count || 0;
    const totalPages = Math.ceil(totalInstances / sizePerPage);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleSearch = () => {
        setPage(0);
        setSearchTerm(searchInput);
        if (!searchField) {
            setSearchField('all');
        }
    };

    const handleBatchArchive = () => {
        if (selectedRows.length === 0) return;
        const communityIds = selectedRows.map((row) => row.community_detection_id);
        dispatch(batchArchiveCommunity({ community_ids: communityIds, archive: true }));
        setTimeout(() => {
            setSelectedRows([]);
            dispatch(getCommunity({appMode, appId: appName ?? undefined, archived: archiveMode, offset: sizePerPage, limit: limit, searchTerm: searchTerm, searchField: searchField}));
        }, 500)
    };

    const handleBatchDelete = () => {
        if (selectedRows.length === 0) return;
        const communityIds = selectedRows.map((row) => row.community_detection_id);
        dispatch(batchDeleteCommunity({ community_ids: communityIds }));
        setTimeout(() => {
            setSelectedRows([]);
            dispatch(getCommunity({appMode, appId: appName ?? undefined, archived: archiveMode, offset: sizePerPage, limit: limit, searchTerm: searchTerm, searchField: searchField}));
        }, 500)
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
    const handleConfirmDelete = () => {
        handleBatchDelete();
        handleCloseDeleteDialog();
    };

    const handleOpenArchiveDialog = () => setOpenArchiveDialog(true);
    const handleCloseArchiveDialog = () => setOpenArchiveDialog(false);
    const handleConfirmArchive = () => {
        handleBatchArchive();
        handleCloseArchiveDialog();
    };

    

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        selected: selectedRows.map((row) => row.community_detection_id),
        onSelect: (row: any, isSelected: boolean) => {
            setSelectedRows((prev) =>
                isSelected
                    ? [...prev, row]
                    : prev.filter((r) => r.community_detection_id !== row.community_detection_id)
            );
        },
        onSelectAll: (isSelected: boolean, rows: any[]) => {
            setSelectedRows(isSelected ? rows : []);
        },
    };

    useEffect(() => {
        if (params.appId && !isPreloader && !isAppSet) {
            setIsAppSet(true)
            dispatchState({
                type: ActionType.SET_APP_NAME,
                payload: Number(params.appId) ?? null
            })
        }
    }, [params, isPreloader,isAppSet])

    const [hideColumn, setHideColumn] = useState(false)

    useEffect(() => {
            dispatch(getCommunity({appMode, appId: appName ?? undefined, archived: archiveMode, offset: sizePerPage, limit: limit, searchTerm: searchTerm, searchField: searchField}))
    }, [appMode, appName, archiveMode, page, sizePerPage, searchTerm])

    useEffect(() => {
        dispatch(getAppsNames(appMode))
    }, [appMode])

    const dateFormatter = (cell: any, row: any) => {
        let date = row.creation_time
            .replace(/[-]/g, '/')
            .replace(/[T]/g, '\u00A0');
        let jsDate = date.slice(0, 16);
        if (row.creation_time) {
            return (
                <span>
                    <p style={{marginBottom: 0}}>
                      {jsDate}
                    </p>
                </span>
            );
        }
    }


    const columns = [
        {
            dataField: 'community_detection_id',
            // hidden: true,
            text: 'Id',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'creation_time',
            text: 'Date',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dateFormatter,
        },
        {
            dataField: 'run_name',
            text: 'Run Name',
            editable: false,
            sort: true,
            style: {
                // textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'customer_app_name',
            text: `${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}App Name`,
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'customer_app_token',
            text: 'App Token',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'platform',
            text: 'Platform',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: 'user',
            text: 'Researcher',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'auto_approve',
            text: 'Auto Approve',
            sort: true,
            style: { textAlign: 'center', borderBottom: '.5px solid #d0d0d0' }
        },
        {
            dataField: 'auto_deploy',
            text: 'Auto Deploy',
            sort: true,
            style: { textAlign: 'center', borderBottom: '.5px solid #d0d0d0' }
        },
        {
            dataField: 'cnt_total',
            text: '#Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_comm_detection_pos',
            text: '#Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_comm_detection_pos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'pct_comm_detection_pos',
            text: '%Add Comm DP',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_comm_detection_pos} %</p>
                </>
            )
        },
        {
            dataField: 'pct_added_pos',
            text: '%Added Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_added_pos} %</p>
                </>
            )
        },
        {
            dataField: 'cnt_users',
            text: '#Users',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_communities',
            text: '#Comm',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_communities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_sessions',
            text: '#PosSessions',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_sessions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_additional_ids',
            text: '#Pos Additional Ids',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_additional_ids.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_pos_before',
            text: '#Pos Before',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_pos_before.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_added_pos',
            text: '#Added Poss',
            hidden: !hideColumn,
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_added_pos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'pct_emu_comm_detection_pos',
            text: '%Pos Emulators',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_emu_comm_detection_pos} %</p>
                </>
            )
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            text: "Actions",
            formatter: (cellContent: any, row: any, rowIndex: any, extraData: any) => (
                <>
                    <div style={{position: "relative"}}>

                        <div className={'table_action'}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Details</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                                <Link
                                    to="#"
                                    className="text-success"
                                    id='menuapp'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr042.svg'
                                        className='svg-icon-edit svg-icon-2x'
                                    />
                                </Link>
                            </OverlayTrigger>

                        </div>
                    </div>
                </>
            ),
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            navigate(`/community/job/${row.community_detection_id}`)
        },
    };

    useEffect(() => {
        sessionStorage.setItem(SessionStorage.cDetectionPage, String(page))
    }, [page])

    useEffect(() => {
        sessionStorage.setItem(SessionStorage.cDetectionPerPage, String(sizePerPage))
    }, [sizePerPage])


    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handlePageChangeNum = (event: unknown, newPage: number) => {
        setPage(newPage-1);
    };
    
    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSizePerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchField(e.target.value);
    };

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <div className={'comm_table_main'}>
            <PageTitle breadcrumbs={configBreadcrumbs}>Community Detection</PageTitle>

            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialog"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the selected jobs?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} className="button confirm-danger" variant='danger'>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openArchiveDialog}
                onClose={handleCloseArchiveDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialog"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Archive"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to archive the selected jobs?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseArchiveDialog}>Cancel</Button>
                    <Button onClick={handleConfirmArchive} className="button confirm-danger" variant='danger'>
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>

            {communityList ? (
                <>
                    <ToolkitProvider
                        keyField="community_detection_id"
                        data={communityList.jobs ? communityList.jobs : []}
                        columns={columns}
                        search
                        bootstrap4
                    >
                        {
                            (props: any) => (
                                <div style={{padding: '15px'}}>
                                    <div>
                                        <br/>
                                        <div className={'toolbar_table'}>
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSearch();
                                                            }
                                                        }}
                                                    />
                                                    <select
                                                        value={searchField}
                                                        onChange={handleFieldChange}
                                                        className="ml-2"
                                                    >
                                                        <option value="all">All Fields</option>
                                                        <option value="run_name">Run Name</option>
                                                        <option value="app_name">App Name</option>
                                                        <option value="app_token">App Token</option>
                                                        <option value="platform">Platform</option>
                                                        <option value="detection_id">Community ID</option>
                                                        <option value="community_id">Community Index</option>
                                                        <option value="community_share_id">Segment ID</option>
                                                    </select>
                                                    <button className="btn btn-primary ml-2" onClick={handleSearch}>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>


                                            <div className={'filter_toolbar'}>
                                                {/*APP MODE*/}
                                                <select onChange={(e) => {
                                                    setPage(0)
                                                    dispatchState({
                                                        type: ActionType.SET_APP_MODE,
                                                        payload: e.target.value as AppMode
                                                    })
                                                }}
                                                        defaultValue={AppMode.LIVE}
                                                >
                                                    <option value={AppMode.ALL}>- App Mode: All -</option>
                                                    <option value={AppMode.LIVE}>Live</option>
                                                    <option value={AppMode.DEMO}>Demo</option>
                                                    <option value={AppMode.TESTING}>Testing</option>
                                                </select>
                                                {/* CHOOSE APP */}
                                                <select value={appName ?? ''} onChange={(e) => {
                                                    setPage(0)
                                                    dispatchState({
                                                        type: ActionType.SET_APP_NAME,
                                                        payload: Number(e.target.value) ?? null
                                                    })
                                                }}>
                                                    <option
                                                        value={''}>{appNamesList.apps?.length === 0 ? 'No Apps Found' : '-Choose App -'}</option>
                                                    {
                                                        appNamesList.apps?.map((mode: any) => (
                                                            <option key={mode.app_id}
                                                                    value={mode.app_id}>{mode.app_name}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Button
                                                    disabled={selectedRows.length === 0}
                                                    onClick={handleOpenDeleteDialog}
                                                    variant={"danger"}
                                                    className="button danger">
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen027.svg'
                                                        className='svg-icon-del'
                                                    /> 
                                                    Delete Selected
                                                </Button>
                                                <Button
                                                    className="modern-button danger"
                                                    disabled={selectedRows.length === 0}
                                                    onClick={handleOpenArchiveDialog}
                                                >
                                                    Archive Selected
                                                </Button>
                                                {/*ARCHIVE MODE*/}
                                                <ArchiveSwitch checked={archiveMode} onChange={() => {
                                                    setPage(0)
                                                    dispatchState({type: ActionType.TOGGLE_ARCHIVE})
                                                }}/>

                                                <Button onClick={() => setHideColumn(prevState => !prevState)}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/abstract/abs015.svg'
                                                        className='svg-icon-edit svg-icon-2'
                                                    />
                                                    {!hideColumn ? 'Show Data' : "Hide Data"}</Button>
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                    {communityList?.jobs && !isPreloader ?  
                                        <div className={classes.container}>                                <Row>
                                        <Col xl="12" className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField="community_detection_id"
                                                bordered
                                                responsive
                                                stripped
                                                headerWrapperClasses="header-class"
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                            />
                                   <div className={classes.paginationContainer}>
                                        <TablePagination
                                            component="div"
                                            count={totalInstances}
                                            page={page}
                                            onPageChange={handlePageChange}
                                            rowsPerPage={sizePerPage}
                                            onRowsPerPageChange={handleSizeChange}
                                            rowsPerPageOptions={[25, 50, 100]}
                                            classes={{
                                                root: classes.tablePagination,
                                                toolbar: classes.toolbar,
                                                selectLabel: classes.selectLabel,
                                                select: classes.select,
                                                input: classes.input,
                                                actions: classes.actions,
                                                displayedRows: classes.displayedRows
                                              }}
                                        />
                                        <Pagination
                                            count={totalPages}
                                            page={page + 1}
                                            onChange={handlePageChangeNum}
                                            color="primary"
                                            className={classes.muiPagination}
                                        />
                                    </div>
                                        </Col>
                                    </Row>                                        </div>    : null
                                    }
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </>
            ) : (
                <>
                    <p>Loading....</p>
                </>
            )
            }
        </div>
    )
}

export default CommunityTable
