import * as sagaEffects from "redux-saga/effects"

// Login Redux States
import {
    deployCommSuccess,
    getAppsNamesSuccess,
    getCommunityJobCommSuccess,
    getCommunityJobSuccess,
    getCommunitySuccess,
    getSegmentDataSuccess,
    getSegmentHeaderSuccess,
    getSegmentsFiltersSuccess,
    getSummaryTableSuccess,
    getWarningsSuccess,
    preDeployCommSuccess,
    segmentTaggingFail,
    segmentTaggingSuccess,
    updateDecisionSuccess,
    updateWarningSuccess
} from "./actions"
//Static
import StaticValues from "../../../src/_metronic/helpers/StaticValues";

//Include Both Helper File with needed methods
import {
    batchArchiveCommunity,
    batchDeleteCommunity,
    autoApproveCommunityJob,
    deployCommunity,
    getAppsNames,
    getCommunityAll,
    getCommunityJob,
    getCommunityJobComm,
    getSegmentData,
    getSegmentFilters,
    getSegmentHeader,
    getSummaryTable,
    getWarningsList,
    patchCommDetails,
    patchDecisionComm,
    patchSegDetails,
    preDeployCommunity,
    segmentTagging,
    updateWarnings,
} from "../../_metronic/helpers/backend_helper"
import {changePreloader} from "../layout/actions";
import {
    AppMode,
    CommunityDetectionStatus,
    CommunityType,
    FilterRequest,
    GetWarningResponse,
    JobCommunityListResponse,
    SegmentType,
    TagFilter,
    UpdateCommDecisionListRequest,
    UpdateCommDetectionJobsRequest,
    UpdateSegmentRequest,
    UpdateWarningRequest,
    UpsertJobSegmentsHeaderRequest
} from "../../AUTO_GENERATED_TYPES";
import {CommunityTypes} from "../../types/StoreTypes";
import {toast} from "react-toastify";

const {call, put, takeEvery, delay} = sagaEffects as any

function* generalSagaError(error: any) {
    yield put(changePreloader(false))
    // toast.error(error.message)
}

function* getCommunityListSaga({payload}: { payload: { appId?: number | undefined, appMode?: AppMode | undefined, archived?: boolean, limit?: number, offset?: number, searchTerm?: string, searchField?: string } }) {
    yield put(changePreloader(true))
    try {
        let response;
        if(payload){
            response = yield call(getCommunityAll, payload.appId, payload.appMode, payload.archived, payload.limit, payload.offset, payload.searchTerm, payload.searchField)
        }else{
            response = yield call(getCommunityAll)
        }
        yield put(getCommunitySuccess(response, response.total_count))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }

}

function* getCommunityJobSaga({payload: jobId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(getCommunityJob, jobId)
        yield put(getCommunityJobSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }

}

function* getCommunityJobCommSaga({payload: {jobId, type}}: { payload: { jobId: number, type: CommunityType } }) {
    yield put(changePreloader(true))
    try {
        const response: JobCommunityListResponse = yield call(getCommunityJobComm, jobId, type)
        yield put(getCommunityJobCommSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* getAppsNamesSaga({payload: appMode}: { payload: AppMode }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(getAppsNames, appMode)
        yield put(getAppsNamesSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* getSegmentSaga({payload: {cdId, segType}}: { payload: { cdId: number, segType: SegmentType } }) {
    try {
        const response: string = yield call(getSegmentHeader, cdId, segType)
        yield put(getSegmentHeaderSuccess(response))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* getSegmentDataSaga({
                                 payload: {
                                     cdId,
                                     segType,
                                     body,
                                     tag,
                                     itemPp,
                                     page
                                 }
                             }: { payload: { cdId: number, segType: SegmentType, body: FilterRequest, tag: TagFilter, itemPp: number, page: number } }) {
    if (body === null) {
        toast.warn('Check that the Filters and Sort are complete!')
        return
    }
    yield put(getSegmentDataSuccess(''))
    yield put(changePreloader(true))
    try {
        const response: string = yield call(getSegmentData, cdId, segType, body, tag, itemPp, page)
        yield put(getSegmentDataSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* patchDecisionCommSaga({
                                    payload: {
                                        cdId,
                                        data
                                    }
                                }: { payload: { cdId: number, data: UpdateCommDecisionListRequest } }) {
    yield put(changePreloader(true))
    try {
        yield call(patchDecisionComm, cdId, data)
        yield put(changePreloader(false))
        toast.success('Decision successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(updateDecisionSuccess(true, ''))
        yield delay(500)
        yield put(updateDecisionSuccess(false, ''))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* patchCommDetailsSaga({
                                   payload: {
                                       cdId,
                                       data
                                   }
                               }: { payload: { cdId: number, data: UpdateCommDetectionJobsRequest } }) {
    yield put(changePreloader(true))
    try {
        yield call(patchCommDetails, cdId, data)
        yield put(changePreloader(false))
        toast.success('Data successfully updated', {autoClose: StaticValues.waitingTimeForAlert})
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* patchSegDetailsSaga({
                                  payload: {
                                      cdId,
                                      data
                                  }
                              }: { payload: { cdId: number, data: UpsertJobSegmentsHeaderRequest } }) {
    yield put(changePreloader(true))
    try {
        yield call(patchSegDetails, cdId, data)
        yield put(changePreloader(false))
        toast.success('Data successfully updated', {autoClose: StaticValues.waitingTimeForAlert})

    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* getWarningList({payload: cdId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: GetWarningResponse = yield call(getWarningsList, cdId)
        yield put(getWarningsSuccess(response.warnings))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* updateWarningsSaga({
                                 payload: {
                                     cdId,
                                     updateStatus
                                 }
                             }: { payload: { cdId: number, updateStatus: UpdateWarningRequest } }) {

    yield put(changePreloader(true))
    try {
        yield call(updateWarnings, cdId, updateStatus)
        toast.success('Warning have been ignored!', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(updateWarningSuccess(true, ''))
        yield delay(500)
        yield put(updateWarningSuccess(false, ''))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* preDeployCommSaga({payload: cdId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(preDeployCommunity, cdId)
        yield put(preDeployCommSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* deployCommSaga({payload: cdId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(deployCommunity, cdId)
        toast.success('Deployment completed successfully', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(deployCommSuccess(true, '', response))
        yield delay(6000)
        yield put(deployCommSuccess(false, '', response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* segmentTaggingSaga({
                                 payload: {
                                     cdId,
                                     data,
                                     getSegmentAdditionalData
                                 }
                             }: { payload: { cdId: number, data: UpdateSegmentRequest, getSegmentAdditionalData?: any } }) {
    // yield put(changePreloader(true))
    try {
        yield call(segmentTagging, cdId, data)
        toast.success('Tagging action completed', {autoClose: StaticValues.waitingTimeForAlert})
        yield put(segmentTaggingSuccess(true, '', 'tag'))
        yield delay(500)
        yield put(segmentTaggingSuccess(false, '', 'none'))

        if (getSegmentAdditionalData) {
            yield put(getSegmentData(
                getSegmentAdditionalData.cdId,
                getSegmentAdditionalData.segType,
                getSegmentAdditionalData.body,
                getSegmentAdditionalData.tag,
                getSegmentAdditionalData.itemPp,
                getSegmentAdditionalData.page
            ))
        }
        // yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
        yield put(segmentTaggingFail(false, 'tagError'))
        yield delay(500)
        yield put(segmentTaggingFail(false, ''))
    }
}

function* getSummaryTableSaga({payload: cdId}: { payload: number }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(getSummaryTable, cdId)
        yield put(getSummaryTableSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* getSegmentFilterSaga({
                                   payload: {
                                       cdId,
                                       filterDefinitionId,
                                       segmentType
                                   }
                               }: { payload: { cdId: number, filterDefinitionId: number, segmentType: SegmentType } }) {
    yield put(changePreloader(true))
    try {
        const response: string = yield call(getSegmentFilters, cdId, filterDefinitionId, segmentType)
        yield put(getSegmentsFiltersSuccess(response))
        yield put(changePreloader(false))
    } catch (error) {
        yield* generalSagaError(error)
    }
}

function* batchArchiveCommunitySaga({ payload }: { payload: { community_ids: number[]; archive: boolean } }) {
    try {
        const response = yield call(batchArchiveCommunity, payload);
        yield put({
            type: CommunityTypes.BATCH_ARCHIVE_COMMUNITY_SUCCESS,
            payload: {
                msg: response.message || 'Communities archived successfully!',
                community_ids: payload.community_ids,
            },
        });
        toast.success('Communities archived successfully!');
    } catch (error) {
        console.error('Error in saga:', error);
        yield put({
            type: CommunityTypes.BATCH_ARCHIVE_COMMUNITY_FAIL,
            payload: { msg: error || 'Failed to archive communities' },
        });
        toast.error('Failed to archive communities!');
    }
}

function* batchDeleteCommunitySaga({ payload }: { payload: { community_ids: number[]; } }) {
    try {
        const response = yield call(batchDeleteCommunity, payload);
        yield put({
            type: CommunityTypes.BATCH_DELETE_COMMUNITY_SUCCESS,
            payload: {
                msg: response.message || 'Communities deleted successfully!',
                community_ids: payload.community_ids,
            },
        });
        toast.success('Communities deleted successfully!');
    } catch (error) {
        console.error('Error in saga:', error);
        yield put({
            type: CommunityTypes.BATCH_DELETE_COMMUNITY_FAIL,
            payload: { msg: error || 'Failed to delete communities' },
        });
        toast.error('Failed to delete communities!');
    }
}

function* autoApproveCommunitySaga({ payload: cdId }: { payload: number }) {
    yield put(changePreloader(true));
    try {
        const response: string = yield call(autoApproveCommunityJob, cdId);
        yield put({
            type: CommunityTypes.AUTO_APPROVE_COMMUNITY_SUCCESS,
            payload: response,
        });
        toast.success("Auto-approval completed successfully", { autoClose: StaticValues.waitingTimeForAlert });
        yield put(changePreloader(false));
    } catch (error) {
        yield* generalSagaError(error);
        yield put({
            type: CommunityTypes.AUTO_APPROVE_COMMUNITY_FAIL,
            payload: "Auto-approval failed",
        });
    }
}



function* communitySaga() {
    yield takeEvery(CommunityTypes.GET_ALL_COMMUNITY, getCommunityListSaga)
    yield takeEvery(CommunityTypes.GET_COMMUNITY_JOB, getCommunityJobSaga)
    yield takeEvery(CommunityTypes.GET_COMMUNITY_JOB_COMM, getCommunityJobCommSaga)
    yield takeEvery(CommunityTypes.GET_APPS_NAMES, getAppsNamesSaga)
    yield takeEvery(CommunityTypes.GET_SEGMENT_HEADER, getSegmentSaga)
    yield takeEvery(CommunityTypes.GET_SEGMENT_DATA, getSegmentDataSaga)
    yield takeEvery(CommunityTypes.PATCH_DECISION_COMM, patchDecisionCommSaga)
    yield takeEvery(CommunityTypes.PATCH_COMM_DETAILS, patchCommDetailsSaga)
    yield takeEvery(CommunityTypes.PATCH_SEG_DETAILS, patchSegDetailsSaga)
    yield takeEvery(CommunityTypes.GET_WARNINGS, getWarningList)
    yield takeEvery(CommunityTypes.UPDATE_WARNINGS, updateWarningsSaga)
    yield takeEvery(CommunityTypes.PRE_DEPLOY_COMM, preDeployCommSaga)
    yield takeEvery(CommunityTypes.DEPLOY_COMM, deployCommSaga)
    yield takeEvery(CommunityTypes.SEGMENT_TAGGING, segmentTaggingSaga)
    yield takeEvery(CommunityTypes.GET_SUMMARY_TABLE, getSummaryTableSaga)
    yield takeEvery(CommunityTypes.GET_SEGMENTS_FILTERS, getSegmentFilterSaga)
    yield takeEvery(CommunityTypes.BATCH_ARCHIVE_COMMUNITY, batchArchiveCommunitySaga);
    yield takeEvery(CommunityTypes.BATCH_DELETE_COMMUNITY, batchDeleteCommunitySaga);
    yield takeEvery(CommunityTypes.AUTO_APPROVE_COMMUNITY, autoApproveCommunitySaga);
}

export default communitySaga
