import {Button, Modal} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {KTSVG} from "../../../../_metronic/helpers";
//@ts-ignore
import styles from "../../../general-styles/Modals/modals.module.scss";
import {Label} from "reactstrap";
import StaticValues from "../../../../_metronic/helpers/StaticValues";
import React, {useEffect, useState} from "react";
import CreatableSelect from 'react-select/creatable';
//@ts-ignore
import {createConfig, deleteApp} from "../../../../store/actions";
import {
    AppMode, AppNameListResponse,
    ConfigType,
    CreateAppConfigRequest,
    CreateConfigRequest,
    GroupMode
} from "../../../../AUTO_GENERATED_TYPES";
import {Option} from "../../../../types/CommunitySortTypes";
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import {createAppConfig, getAppsNames, getGroupsDefault} from "../../../../_metronic/helpers/backend_helper";
import isValidUrl from "../../../helpers/utils/validations/isValidUrl";
import isValidJSON from "../../../helpers/utils/validations/isValidJSON";
import isValidString from "../../../helpers/utils/validations/isValidString";
import isValidInt from "../../../helpers/utils/validations/isValidInt";
import isValidBoolean from "../../../helpers/utils/validations/isValidBoolean";
import isValidRuleSet from "../../../helpers/utils/validations/isValidRuleSet";
import {toast} from "react-toastify";
import ReactSwitch from "react-switch";
import {flushSync} from "react-dom";
import isValidFloat from "../../../helpers/utils/validations/isValidFloat";
import Select from "react-select";


interface NewConfigProps {
    show: boolean,
    handleClose: () => void,
    isEdit?: boolean,
}

const NewAppConfig = ({show, handleClose, isEdit}: NewConfigProps) => {
    const queryClient = useQueryClient()
    const [mode, setMode] = useState<GroupMode | ''>('')

    const {
        data: groupsList,
    } = useQuery([QueryKeys.ConfigGroup, mode], () => getGroupsDefault(mode as GroupMode,false,true), {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(mode),
    })

    const appList = queryClient.getQueryData<AppNameListResponse | undefined>([QueryKeys.AppNames])

    const groupOptions = groupsList?.groups?.map(group => ({
        value: String(group.id),
        label: `${group.group_name} - ${group.mode}`
    }))

    const [name, setName] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [validValue, setValidValue] = useState<any>([])
    const [group, setGroup] = useState<Option | null>()
    const [selectedGroup, setSelectedGroup] = useState<number>()
    const [type, setType] = useState<ConfigType>(ConfigType.INT)
    const [selectedApp, setSelectedApp] = useState<Option | null>(null)


    const [formIsValid, setFormIsValid] = useState<boolean>(false)
    const [typeIsValid, setTypeIsValid] = useState<boolean>(false)
    const [checkFieldsModal, setCheckFieldsModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [verify, setVerify] = useState<boolean>(false)

    const {mutate: createConfigDefault} = useMutation((requestBody: CreateAppConfigRequest) => toast.promise(createAppConfig(requestBody), {
        pending: 'Creating new app config...',
        success: 'Created Successfully!',
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.AppConfig])
        },
    })

    useEffect(() => {
        setGroup(null)
    }, [mode])

    useEffect(() => {
        if(group){
        setSelectedGroup(+group.value)
        }
    }, [group, validValue])

    useEffect(() => {
        if (name.length > 0 && type.length > 0 && value.length > 0 && selectedGroup && selectedApp && type !== 'ENUM' && typeIsValid) {
            setFormIsValid(true)
        } else if (name.length > 0 && type.length > 0 && value.length > 0 && selectedGroup && selectedApp && validValue.length > 0 && typeIsValid) {
            setFormIsValid(true)
        } else {
            setFormIsValid(false)
        }
    }, [name, type, value, selectedGroup, validValue, selectedApp, typeIsValid]);

    const checkTypeValue = (value: any) => {
        setVerify(true)
        if (type === ConfigType.INT && !isValidInt(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'INT' requires a numeric input !")
            setCheckFieldsModal(true)

        } else if (type === ConfigType.BOOLEAN && !isValidBoolean(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'BOOLEAN' requires a boolean input (true/false)!")
            setCheckFieldsModal(true)

        } else if (type === ConfigType.JSON && !isValidJSON(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'JSON' requires a JSON object input !")
            setCheckFieldsModal(true)

        } else if (type === ConfigType.URL && !isValidUrl(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'URL' requires a valid url address ('http://exemple.com')!")
            setCheckFieldsModal(true)


        } else if (type === ConfigType.STR && !isValidString(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'STR' requires a string input !")
            setCheckFieldsModal(true)
        } else if (type === ConfigType.FLOAT && !isValidFloat(value)) {
            setTypeIsValid(false)
            setErrorMessage("Type 'FLOAT' requires a numeric input !")
            setCheckFieldsModal(true)

        } else if (type === ConfigType.JSON && group?.label?.includes('AUTO_APPROVE') && !isValidRuleSet(JSON.parse(value))) {
            setTypeIsValid(false);
            setErrorMessage("Invalid rule set structure. Make sure all logical operators are 'AND'/'OR' and conditions are complete.");
            setCheckFieldsModal(true);
        } else {
            setTypeIsValid(true)
            console.log('true')
        }
    }

    const handleCloseModal = () => {
        setName('')
        setMode('')
        setType(ConfigType.INT)
        setValue('')
        if (validValue.length > 0) {
            setValidValue('')
        }
        setGroup(null)
        setDescription('')
        setVerify(false)
        setSelectedApp(null)
        handleClose()
    }


    const newConfig = async () => {
        const configData: CreateConfigRequest = {
            config_name: name,
            config_type: type,
            config_value: value,
            config_group_id: Number(selectedGroup),
            config_description: description ? description : '',
            config_valid_values: validValue ? validValue.map((value: any) => value.value) : null
        }

        const createRequest: CreateAppConfigRequest = {
            app_id: +selectedApp!.value,
            create_config_request: configData
        }

        createConfigDefault(createRequest)
        handleCloseModal()

    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                backdrop={'static'}
                centered
            >
                {/*@ts-ignore*/}
                <SweetAlert
                    show={checkFieldsModal}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnBsStyle={'danger'}
                    title={"Wrong Format Type"}
                    onConfirm={() => {
                        setCheckFieldsModal(false)
                    }}
                    onCancel={() => setCheckFieldsModal(false)}
                >
                    {errorMessage}
                </SweetAlert>

                <div>
                    <div className='modal-content'>
                        <div className={styles.flex_row_between}>
                            <div className={styles.title_close_space}>
                                <h2>{isEdit ? <span style={{fontWeight: 400}}>Update {name} </span> :
                                    <span style={{fontWeight: 400}}>Create an App Config</span>}
                                </h2>
                            </div>
                            <div className='modal_close_btn'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>
                        </div>
                        <form className={'form_container'} action="">
                            <div className={styles.modal_block_general}>
                                <Label className={'label_font_size'}> Name <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>
                                <input value={name || ''}
                                       onBlur={() => setVerify(true)}
                                       className={name === "" && verify ? styles.borderRed : styles.classic_input}
                                       onChange={(e) => setName(e.target.value)}
                                       type="text"/>
                                <br/>
                                <Label className={styles.label_font_size}>Group Mode <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>
                                <select
                                    value={mode}
                                    className={styles.classic_input}
                                    onChange={(e) => setMode(e.target.value as GroupMode)}>
                                    <option value={''}>Select Mode</option>
                                    <option value={GroupMode.LIVE}>Live</option>
                                    <option value={GroupMode.DEMO}>Demo</option>
                                    <option value={GroupMode.TEST}>Test</option>

                                </select>
                                <br/>
                                <Label className={styles.label_font_size}>Group <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>
                                <div
                                    onBlur={() => setVerify(true)}
                                    className={!group && verify ? styles.borderRed2 : styles.classic_input}
                                >
                                    <Select
                                        options={groupOptions ?? []}
                                        value={group}
                                        onChange={e => setGroup(e as Option)}
                                        isDisabled={!mode}
                                        placeholder={!mode ? 'Select mode first...' : 'Select groups'}
                                    />
                                </div>
                                <br/>
                                <Label className={'label_font_size'}> App <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>
                                <Select value={selectedApp}
                                        options={appList?.apps?.map((value) => (
                                            {
                                                value: String(value.app_id),
                                                label: `${value.app_name} - ${value.app_token}`
                                            }
                                        ))}
                                        placeholder={'Select App'}
                                        onChange={(e) => setSelectedApp(e!)}
                                />
                                <br/>
                                <Label className={styles.label_font_size}>Type <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>
                                <select
                                    className={styles.classic_input}
                                    value={type || ''} onChange={(e) => {
                                    setTypeIsValid(false)
                                    setType(e.target.value as ConfigType)
                                    if (e.target.value === ConfigType.BOOLEAN) {
                                        setValue('true')
                                        setTypeIsValid(true)
                                    } else {
                                        setValue('')
                                    }
                                    setValidValue([])
                                }}
                                >
                                    {StaticValues.configType.map((config: any) => (
                                        <option key={config.value} value={config.value}>{config.label}</option>
                                    ))}
                                </select>
                                <br/>
                                {type !== ConfigType.ENUM && <Label className={styles.label_font_size}>Value <span
                                    className={styles.mandatory_star}>*</span>
                                </Label>}
                                {type === ConfigType.ENUM &&
                                    <>
                                        <Label>Valid Values <span
                                            className={styles.mandatory_star}>*</span></Label>
                                        <div className={styles.classic_input}>
                                            <CreatableSelect
                                                isClearable
                                                isMulti
                                                onChange={(value) => {
                                                    flushSync(() => setValidValue(value))
                                                    setTypeIsValid(true)
                                                    setValue(value.at(-1).value)
                                                }}
                                                value={validValue || []}
                                                createOptionPosition={'first'}
                                                isValidNewOption={((inputValue, value1, options) => {
                                                    if (!isValidString(inputValue)) {
                                                        return false
                                                    }
                                                    if (options.includes((option: Option) => option.value === inputValue)) {
                                                        toast.warn('Option already exists in the enum!')
                                                        return false
                                                    }
                                                    return true
                                                })}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                noOptionsMessage={() => <div>
                                                    <div>Start writing options,</div>
                                                    <div>Press Enter to add.</div>
                                                </div>}
                                            />
                                        </div>
                                        <br/>
                                        <Label className={styles.label_font_size}>Value <span
                                            className={styles.mandatory_star}>*</span>
                                        </Label>
                                    </>
                                }
                                {type === ConfigType.ENUM && validValue ? (
                                    <select
                                        value={value}
                                        onBlur={(e) => checkTypeValue(e.target.value)}
                                        onChange={(e) => setValue(e.target.value)}>
                                        {validValue?.map((value: any) => (
                                            <option key={value.value} value={value.value}>{value.value}</option>
                                        ))}
                                    </select>
                                ) : type === ConfigType.BOOLEAN ? <ReactSwitch checked={value === 'true'}
                                                                               onChange={(checked => setValue(checked ? 'true' : 'false'))}
                                                                               offColor={'#2d6f94'}
                                                                               onColor={'#0095e8'}/> : (
                                    <input
                                        onBlur={(e) => setTimeout(() => checkTypeValue(e.target.value), 50)}
                                        value={value || ''}
                                        className={value === "" && verify ? styles.borderRed : styles.classic_input}
                                        onChange={(e) => setValue(e.target.value)} type="text"/>
                                )}
                                {/*TODO: ADD OPTION FOR BOOLEAN*/}
                                {/*TODO: ADD OPTION FOR JSON*/}
                                <br/>
                                <Label className={styles.label_font_size}>Description</Label>
                                <input
                                    // onBlur={(e) => checkFields(e.target.value)}
                                    value={description || ''}
                                    className={styles.classic_input}
                                    onChange={(e) => setDescription(e.target.value)} type="text"/>
                                <br/>
                                <div className={styles.confirm_btn}>
                                    <Button
                                        disabled={!formIsValid}
                                        onClick={newConfig}
                                    >
                                        {isEdit ? 'Update' : 'Create'}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewAppConfig
