interface Rule {
    field?: string;
    operator?: string;
    value?: string | number | boolean;
    logical_operator?: 'AND' | 'OR';
    conditions?: Rule[];
}

const VALID_LOGICAL_OPERATORS = ['AND', 'OR'];
const VALID_OPERATORS = ['==', '=', '!=', '>', '>=', '<', '<=', 'in', 'not in'];

export default function isValidRuleSet(rule: any, depth: number = 0): boolean {
    if (typeof rule !== 'object' || rule === null) return false;

    const isString = (val: any) => typeof val === 'string';

    if ('field' in rule && 'operator' in rule && 'value' in rule) {
        return isString(rule.field) &&
               isString(rule.operator) &&
               VALID_OPERATORS.includes(rule.operator) &&
               (
                   typeof rule.value === 'string' ||
                   typeof rule.value === 'number' ||
                   typeof rule.value === 'boolean'
               );
    }

    if ('logical_operator' in rule && Array.isArray(rule.conditions)) {
        return (
            VALID_LOGICAL_OPERATORS.includes(rule.logical_operator) &&
            rule.conditions.length > 0 &&
            rule.conditions.every(sub => isValidRuleSet(sub, depth + 1))
        );
    }

    return false;
}
